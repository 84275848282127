import downloadFile from 'downloadjs'
import { getPromise } from './promise'

interface DownloadRequest {
  name?: string
  raw?: File | Blob | Uint8Array | string
  url?: string
}
export default ({ name, raw, url }: DownloadRequest) => {
  const { promise, resolve, reject } = getPromise()
  if (raw) {
    downloadFile(raw, name)
    resolve(true)
    return promise
  }
  if (url) {
    const ajax = new XMLHttpRequest()
    ajax.open('GET', url, true)
    ajax.responseType = 'blob'
    ajax.setRequestHeader('Cache-Control', 'no-cache')
    ajax.setRequestHeader('Expires', '0')
    ajax.onload = function (e) {
      downloadFile((e.target as XMLHttpRequest).response, name)
      resolve(true)
    }
    ajax.onerror = function () {
      reject()
    }
    setTimeout(() => ajax.send(), 0)
    return promise
  }
  resolve(true)
  return promise
}
